.modal-overlay-res {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: right;
    align-items: center;
    font-family:"proxima_nova_rgregular";
  }
  
  .modal-content-res {
    background-color: #18181A;
    color: #f0f0f0;
    border-radius: 8px;
    max-height: 360px; 
    max-width: 260px; 
    width: 100%;
    right: 20px;
    position: fixed;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto; 

  }
  
  
@media (max-width: 768px) {

  .modal-content-res {
    max-width: 60%; 
    max-height: 50%; 
  }

  
  .modal-content-res h3 {
    font-family:"proxima_nova_rgregular";
    margin-top: 20px;
    margin-left: 16%;
    font-size: 16px;
    color: #f0f0f0;
     margin-bottom: 15px;
  }

  .resolution-item {
    font-family:"proxima_nova_rgregular";
    font-size: 12px;
    color: #ffffff6a;
    cursor: pointer;
    transition: background-color 0.12s ease, color 0.3s ease;
  }

  .res-text{
    margin-left: 10%;
    font-size: 12px;
   }

   .res-text-active{
    margin-left: 5%;
    font-size: 12px;
   }
 
   .res-icon{
     width: 16px;
    }

}

@media (max-width: 480px) {
  .modal-content-res {
    max-width: 60%; 
    max-height: 50%; 
  }

  
  .modal-content-res h3 {
    font-family:"proxima_nova_rgregular";
    margin-top: 20px;
    margin-left: 16%;
    font-size: 16px;
    color: #f0f0f0;
    margin-bottom: 15px;
  }

  .resolution-item {
    font-family:"proxima_nova_rgregular";
    font-size: 12px;
    color: #ffffff6a;
    cursor: pointer;
    transition: background-color 0.12s ease, color 0.3s ease;
  }

  .res-text{
    margin-left: 10%;
    font-size: 12px;
   }

   .res-text-active{
    margin-left: -3%;
    font-size: 12px;
   }
 
   .res-icon{
     width: 16px;
    }
 
}

  .modal-content-res h3 {
    font-family:"proxima_nova_rgregular";
    margin-top: 20px;
    margin-left: 16%;
    font-size: 20px;
    color: #f0f0f0;
     margin-bottom: 15px;
  }
  
  .resolution-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .resolution-item {
    font-family:"proxima_nova_rgregular";
    font-size: 16px;
    color: #ffffff6a;
    cursor: pointer;
    transition: background-color 0.12s ease, color 0.3s ease;
  }
  
  .resolution-item:hover {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.155);
    color: #fff;
    font-weight: bold;
    position: relative; 

  }
  
  .selected-resolution {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.347); 
    color: #fff;
    font-weight: bold;
    position: relative; 
  }

  .res-text{
   margin-left: 15%;
   font-size: 16px;

  }

  .res-icon{
    width: 24px;
   }

   .res-text-active{
    font-size: 16px;
  }
  

  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: bold;
  }
  
  .close-button:hover {
    color: #ffffff;
  }
  